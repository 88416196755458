import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

const contact = () => {
  return (
    <Layout>
      <SEO
        title="Contact"
        description="If you want to contact me, please submit your question or suggestion by filling in this form."
      />
      <div className="container">
        <h1>Contact</h1>
        <ul className="breadcrumb" style={{ paddingTop: "1rem" }}>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>Contact</li>
        </ul>
        <p>
          If you want to contribute or just say hi, drop me a message by filling
          in the form below.
        </p>
        <p>
          If you have a question, please make sure you have read the{" "}
          <Link to="/faq/">Frequently Asked Questions</Link> page first. Maybe
          the answer is already in there.
        </p>
        <h2>Contact Form</h2>
        <form name="contact" method="POST">
          <input type="hidden" name="form-name" value="contact" />
          <p style={{ display: "none" }}>
            <label>
              Don’t fill this out if you're human: <input name="bot-field" />
            </label>
          </p>
          <label htmlFor="name" aria-label="Type your Name">
            Name
          </label>
          <input
            type="text"
            id="name"
            name="name"
            placeholder="Your Name"
            required="required"
          />
          <label htmlFor="email" aria-label="Type your Email">
            Email
          </label>
          <input
            type="email"
            id="email"
            name="email"
            placeholder="Your Email"
            required="required"
          />
          <label htmlFor="message" aria-label="Type your Message">
            Message
          </label>
          <textarea
            id="message"
            name="message"
            rows="5"
            cols="50"
            minLength="10"
            placeholder="Your Message"
            required="required"
          ></textarea>
          <button type="submit" aria-label="Send Message">
            Send
          </button>
        </form>
      </div>
    </Layout>
  )
}

export default contact
